const PhotographyImages = [
    { src: require('../images/photography/studio/IMG_1.JPG'), title: 'feather-boas' },
    { src: require('../images/photography/studio/IMG_2.JPG'), title: 'feather-boas' },
    { src: require('../images/photography/studio/IMG_3.JPG'), title: 'feather-boas' },
    { src: require('../images/photography/studio/IMG_4.JPG'), title: 'paper-flower' },
    { src: require('../images/photography/studio/IMG_5.JPG'), title: 'paper-flower' },
    { src: require('../images/photography/studio/IMG_6.JPG'), title: 'paper-flower' },
    { src: require('../images/photography/studio/IMG_7.JPG'), title: 'petal-mirror' },
    { src: require('../images/photography/studio/IMG_8.JPG'), title: 'petal-mirror' },
    { src: require('../images/photography/studio/IMG_9.JPG'), title: 'petal-mirror' },
    { src: require('../images/photography/studio/IMG_10.JPG'), title: 'frozen-flower' },
    { src: require('../images/photography/studio/IMG_11.JPG'), title: 'frozen-flower' },
    { src: require('../images/photography/studio/IMG_12.JPG'), title: 'frozen-flower' },
    { src: require('../images/photography/nature/IMG_1.JPG'), title: 'pink-rose' },
    { src: require('../images/photography/nature/IMG_2.JPG'), title: 'pink-rose' },
    { src: require('../images/photography/nature/IMG_3.JPG'), title: 'pink-rose' },
    { src: require('../images/photography/nature/IMG_4.JPG'), title: 'peach-rose' },
    { src: require('../images/photography/nature/IMG_5.JPG'), title: 'peach-rose' },
    { src: require('../images/photography/nature/IMG_6.JPG'), title: 'peach-rose' },
    { src: require('../images/photography/nature/IMG_7.JPG'), title: 'cream-rose' },
    { src: require('../images/photography/nature/IMG_8.JPG'), title: 'cream-rose' },
    { src: require('../images/photography/nature/IMG_9.JPG'), title: 'cream-rose' },
    { src: require('../images/photography/nature/IMG_10.JPG'), title: 'yellow-rose' },
    { src: require('../images/photography/nature/IMG_11.JPG'), title: 'yellow-rose' },
    { src: require('../images/photography/nature/IMG_12.JPG'), title: 'yellow-rose' },
    { src: require('../images/photography/nature/IMG_13.JPG'), title: 'leaf' },
    { src: require('../images/photography/nature/IMG_14.JPG'), title: 'leaf' },
    { src: require('../images/photography/nature/IMG_15.JPG'), title: 'leaf' },
    { src: require('../images/photography/nature/IMG_16.JPG'), title: 'grass' },
    { src: require('../images/photography/nature/IMG_17.JPG'), title: 'grass' },
    { src: require('../images/photography/nature/IMG_18.JPG'), title: 'grass' },
    { src: require('../images/photography/nature/IMG_19.JPG'), title: 'purple-flower' },
    { src: require('../images/photography/nature/IMG_20.JPG'), title: 'purple-flower' },
    { src: require('../images/photography/nature/IMG_21.JPG'), title: 'purple-flower' },
];

export default PhotographyImages;