import React from "react";
import UnderConstruction from './UnderConstruction.js';

class Playground extends React.Component {
  render() {
    return (
      <UnderConstruction />
    );
  }
}

export default Playground;